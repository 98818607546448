/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { TranslateService } from '@ngx-translate/core'
import { filter } from 'rxjs'
// npm install @datadog/browser-rum
// import { datadogRum } from '@datadog/browser-rum'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title: string = 'Sistema de inscrições'

  constructor(private swUpdate: SwUpdate, private translate: TranslateService) {
    this.translate.use(this.translate.getBrowserLang() ?? 'pt')
  }

  ngOnInit(): void {
    // datadogRum.init({
    //   applicationId: 'c112082f-5a2b-4a13-814b-463c3ee57b85',
    //   clientToken: 'pube0726a5498995dfd18293af99b7beaf0',
    //   site: 'datadoghq.com',
    //   service:'sinsc-dev',
    //   env:'dev',
    //   sampleRate: 100,
    //   sessionReplaySampleRate: 20,
    //   trackInteractions: true,
    //   trackResources: true,
    //   trackLongTasks: true,
    //   defaultPrivacyLevel:'mask-user-input'
    // })
    // datadogRum.startSessionReplayRecording()
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          confirm('Nova versão do sistema disponível. Deseja atualizar?')
          document.location.reload()
        })
    }
  }
}
