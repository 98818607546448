/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { RecursoService } from 'src/app/services/recurso.service'
import { ToastService } from '../../toast/services/toast.service'
import { RecursosDisponiveis } from 'src/app/models/recursos-disponiveis'

@Component({
  selector: 'app-recurso-cadastrar',
  templateUrl: './recurso-cadastrar.component.html',
  styleUrls: ['./recurso-cadastrar.component.css'],
})
export class RecursoCadastrarComponent implements OnInit {
  id: number = 0
  recursosDisponiveis?: RecursosDisponiveis
  conferencia: boolean | null = false
  file?: File | null
  inputClass: string = 'form-control'

  constructor(
    private route: ActivatedRoute,
    private recursoService: RecursoService,
    private toastService: ToastService,
    private router: Router,
    private location: Location
  ) {}

  form = new FormGroup({
    tipoRecurso: new FormControl('', Validators.required),
    texto: new FormControl('', Validators.required),
    anexo: new FormControl(),
  })

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      this.recursoService.recursosDisponiveis(this.id).subscribe(recursosDisponiveis => {
        this.recursosDisponiveis = recursosDisponiveis
      })
    })
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0]
    if (this.file) {
      if (this.file.type !== 'application/pdf' && this.file.type !== 'image/png' && this.file.type !== 'image/jpeg') {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast('Atenção', 'Apenas arquivos do tipo: PDF, PNG e JPEG são permitidos.')
        this.file = null
        this.form.controls['anexo'].reset()
        return
      }
      if (this.file.size > 512000000) {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast('Atenção', 'O arquivo excedeu o limite de 500 MB permitidos.')
        this.file = null
        this.form.controls['anexo'].reset()
        return
      }
      this.inputClass = 'form-control is-valid'
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  voltar(): void {
    this.location.back()
  }

  cancelar(): void {
    this.conferencia = false
    this.form.enable()
  }

  conferir(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    this.conferencia = null
    setTimeout(() => {
      this.conferencia = true
    }, 300)
    this.form.disable()
    this.toastService.showWarningToast('Atenção', 'Por favor, confira todos os dados antes de confirmar')
  }

  enviar(): void {
    var jsonData = {
      tipoRecurso: this.form.get('tipoRecurso')?.value,
      texto: this.form.get('texto')?.value,
    }
    this.recursoService.novoRecurso(this.id, jsonData).subscribe(recurso => {
      if (recurso) {
        const formData = new FormData()
        if (this.file) {
          formData.append('file', this.file)
          this.recursoService.anexar(recurso.id, formData).subscribe(recurso => {
            if (!recurso) {
              this.toastService.showErrorToast(
                'Atenção',
                'Não foi possível registrar o arquivo, por favor entre em contato com a unidade!'
              )
            }
          })
        }
        this.toastService.showSuccessToast('Atenção', 'Recurso registrado com sucesso')
        this.router.navigate(['/candidato/recursos'])
      } else {
        this.toastService.showErrorToast('Atenção', 'Falha ao registrar')
      }
    })
  }
}
