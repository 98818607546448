/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EMPTY, catchError as catchErrorRxJS, Observable, OperatorFunction } from 'rxjs'
import { ToastService } from '../modules/toast/services/toast.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private router: Router, private toastService: ToastService) {}

  catchError<T>(): OperatorFunction<T, T> {
    return catchErrorRxJS<T, Observable<T>>(err => {
      if (err.status == 0) {
        this.toastService.showErrorToast('Atenção', 'Falha na comunicação com o servidor')
      } else if (err.status == 404) {
        this.toastService.showErrorToast('Atenção', 'O recurso solicitado não está disponível')
      } else if (err.status == 403) {
        localStorage.removeItem('sinsc-candidato-token')
        localStorage.removeItem('sinsc-candidato-cpf')
        localStorage.removeItem('sinsc-candidato-email')
        this.toastService.showErrorToast('Atenção', 'Acesso negado, faça sua identificação no sistema')
        this.router.navigate(['sistema/entrar'])
      } else {
        this.toastService.showErrorToast('Atenção', err.message)
      }
      return EMPTY
    })
  }
}
