/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Usuario } from '../models/usuario'
import { Router } from '@angular/router'
import { ToastService } from '../modules/toast/services/toast.service'

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private baseUrl: string

  constructor(private http: HttpClient, private router: Router, private toastService: ToastService) {
    this.baseUrl = environment.baseUrlApi
  }

  executeLogin(username: string, password: string): void {
    var formData: any = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    this.login(formData).subscribe(token => {
      if (token != '') {
        this.get().subscribe(usuario => {
          if (usuario) {
            this.toastService.showSuccessToast('Seja bem vindo', `Email ${usuario.email} identificado com sucesso`)
            const redirectConcursoId = localStorage.getItem('sinsc_redirect_concurso_id')
            if (redirectConcursoId != null) {
              localStorage.removeItem('sinsc_redirect_concurso_id')
              this.router.navigate([`/concurso/${redirectConcursoId}/inscricao`])
            } else {
              this.router.navigate(['/candidato/inscricoes'])
            }
          } else {
            this.toastService.showErrorToast('Atenção', 'Email e/ou senha inválido(s)')
          }
        })
      } else {
        this.toastService.showErrorToast('Atenção', 'Email e/ou senha inválido(s)')
      }
    })
  }

  private login(form: FormData) {
    return this.http.post(`${this.baseUrl}/privado/login`, form, { responseType: 'text' }).pipe(
      map(token => {
        if (token) {
          localStorage.setItem('sinsc-candidato-token', token)
        } else {
          localStorage.removeItem('sinsc-candidato-token')
        }
        return token
      })
    )
  }

  logout() {
    this.toastService.showSuccessToast('Atenção', 'Acesso finalizado com sucesso')
    localStorage.removeItem('sinsc-candidato-token')
    localStorage.removeItem('sinsc-candidato-email')
    this.router.navigate(['/sistema/entrar'])
  }

  timeout() {
    this.toastService.showWarningToast('Atenção', 'Sessão expirada, por favor identifique-se')
    localStorage.removeItem('sinsc-candidato-token')
    localStorage.removeItem('sinsc-candidato-email')
    this.router.navigate(['/sistema/entrar'])
  }

  get(): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.baseUrl}/privado/candidato`).pipe(
      map(usuario => {
        localStorage.setItem('sinsc-candidato-email', usuario.email)
        return usuario
      })
    )
  }

  isLoggedIn() {
    let token = localStorage.getItem('sinsc-candidato-token')
    return !(token === null)
  }

  senhaRecuperar(email: string) {
    return this.http.get(`${this.baseUrl}/publico/senha-recuperar/${email}`, { responseType: 'text' }).pipe(
      map(email => {
        return email
      })
    )
  }

  senhaAlterar(jsonData: any) {
    return this.http.post(`${this.baseUrl}/privado/candidato/senha-alterar`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }

  senhaRedefinir(jsonData: any) {
    return this.http.post(`${this.baseUrl}/publico/senha-redefinir`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }
}
