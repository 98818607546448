/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { InscricaoComplemento } from 'src/app/models/inscricao-complemento'
import { InscricaoComplementoService } from 'src/app/services/inscricao-complemento.service'
import { ModalService } from '../file-preview-modal/services/modal.service'
import { ToastService } from '../../toast/services/toast.service'
import { Subscription } from 'rxjs/internal/Subscription'
import { DownloadService } from 'src/app/services/download.service'

@Component({
  selector: 'app-formulario-file',
  templateUrl: './formulario-file.component.html',
  styleUrls: ['./formulario-file.component.css'],
})
export class FormularioFileComponent {
  @Output() stateChanged = new EventEmitter()
  @Input() inscricaoComplemento!: InscricaoComplemento
  @Input() periodoPermitido?: boolean
  file?: File
  inputClass: string = 'form-control'
  loading: boolean = false
  private eventsSubscription?: Subscription

  constructor(
    private inscricaoComplementoService: InscricaoComplementoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private modalService: ModalService
  ) {}

  onFileSelected(event: any): void {
    this.file = event.target.files[0]
    if (this.file) {
      if (this.file.type !== 'application/pdf') {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast('Atenção', 'Apenas arquivos do tipo PDF são permitidos.')
        return
      }
      if (this.file.size > 512000000) {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast('Atenção', 'O arquivo excedeu o limite de 500 MB permitidos.')
        return
      }
      this.inputClass = 'form-control'
      this.modalService.showPreview(this.file, this.inscricaoComplemento.grupo, this.inscricaoComplemento.explicacao)
      this.eventsSubscription = this.modalService.modalEventsEnviar.subscribe( result => {
        if(result) this.enviar()
        else (event.target as HTMLInputElement).value = ''
        this.eventsSubscription?.unsubscribe()
      })
    }
  }

  enviar(): void {
    if (this.file) {
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.inscricaoComplemento) {
        this.loading = true
        this.inscricaoComplementoService
          .upload(this.inscricaoComplemento.idInscricao, this.inscricaoComplemento.id, formData)
          .subscribe(inscricaoComplemento => {
            if (inscricaoComplemento) {
              this.inscricaoComplemento = inscricaoComplemento
              this.toastService.showSuccessToast('Parabéns', 'Arquivo enviado com sucesso')
            } else {
              this.toastService.showErrorToast('Atenção', 'Não foi possível enviar o arquivo')
            }
            this.loading = false
            this.stateChanged.emit()
          })
      }
    }
  }

  open(): void {
    // this.downloadService.complemento(this.inscricaoComplemento.id)
    this.downloadService.complementoAcessoDireto(this.inscricaoComplemento.idConcurso, this.inscricaoComplemento.valor)
  }
}
