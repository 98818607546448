/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-senha-recuperar',
  templateUrl: './senha-recuperar.component.html',
  styleUrls: ['./senha-recuperar.component.css'],
})
export class SenhaRecuperarComponent {
  form = new FormGroup({
    email: new FormControl('', Validators.required),
  })

  constructor(private usuarioService: UsuarioService, private toastService: ToastService) {}

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    if (this.form.get('email')?.value) {
      let email = this.form.get('email')?.value ?? ''
      this.usuarioService.senhaRecuperar(email).subscribe(email => {
        if (email != '') {
          this.toastService.showSuccessToast('Atenção', `Siga as orientações enviadas para o e-mail ${email}`)
        } else {
          this.toastService.showErrorToast('Atenção', 'email não encontrado')
        }
        this.form.reset()
      })
    }
  }
}
